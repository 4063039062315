.esp-page-title-default {
    @include default-header-font($text-xl, $palette-black-0, 500, 2.2);
}

.esp-page-title-white {
    @include default-header-font($text-xl, $palette-white-0, 500, 1.2);
}

.esp-page-title-okta {
    @include default-header-font($text-xlg, $palette-black-0, 500, 1.2);
}
