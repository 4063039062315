// Loading Animation Circle

@keyframes loading-animation-circle {
	100% {
		transform: rotate(360deg);
	}
}

// Loading Animation

%loading-animation {
	@include clay-spinner-variant($loading-animation);
}

.loading-animation {
	@extend %loading-animation !optional;
}

// Loading Animation Squares

@keyframes loading-animation-squares-box-1 {
	0% {
		left: 0;
		opacity: 0.4;
		top: 0;
		transform: scale(1);
	}
	25% {
		left: calc(100% - 1em);
		opacity: 0.4;
		top: 0;
		transform: scale(1);
	}
	50% {
		left: calc(100% - 1.5em);
		opacity: 1;
		top: calc(100% - 1.5em);
		transform: scale(2);
	}
	75% {
		left: calc(1em / 2);
		opacity: 1;
		top: calc(100% - 1.5em);
		transform: scale(2);
	}
}

@keyframes loading-animation-squares-box-2 {
	0% {
		left: calc(100% - 1.5em);
		opacity: 1;
		top: calc(100% - 1.5em);
		transform: scale(2);
	}
	25% {
		left: calc(1em / 2);
		opacity: 1;
		top: calc(100% - 1.5em);
		transform: scale(2);
	}
	50% {
		left: 0;
		opacity: 0.4;
		top: 0;
		transform: scale(1);
	}
	75% {
		left: calc(100% - 1em);
		opacity: 0.4;
		top: 0;
		transform: scale(1);
	}
}

.loading-animation-squares {
	@include clay-spinner-variant($loading-animation-squares);
}

// Loading Animation Sizes

@each $selector, $value in $loading-animation-sizes {
	$_selector: if(
		starts-with($selector, '.') or
			starts-with($selector, '#') or
			starts-with($selector, '%'),
		$selector,
		str-insert($selector, '.', 1)
	);

	#{$_selector} {
		@include clay-spinner-variant($value);
	}
}

// Loading Animation Variants

@each $selector, $value in $loading-animation-palette {
	$_selector: if(
		starts-with($selector, '.') or
			starts-with($selector, '#') or
			starts-with($selector, '%'),
		$selector,
		str-insert($selector, '.', 1)
	);

	#{$_selector} {
		@include clay-spinner-variant($value);
	}
}
