.multistepnav-content {
    flex-direction: column;
    display: flex;
    padding: 10px 0 0 0;
    margin: 0;
}
.stepper-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    align-items: flex-start;
}
.multi-step-nav-collapse-sm {
    width: 60%;
    height: 47px;
    padding: 16px 24px;
}
.multi-step-item {
    flex-direction: row;
    display: flex;
}
.multi-step-icon {
    height: 24px;
    width: 24px;
    border-radius: 1000px;
    gap: 8px;
}
.multi-step-title {
    height: 21px;
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 700;
    font-size: $text-md;
    line-height: 21px;
}
.multi-step-indicator {
    height: 24px;
}
.multi-step-item-expand .multi-step-divider {
    border-radius: 3px;
    width: 31px;
    height: 1px;
    margin-left: 8px;
}
.multi-step-item-expand {
    flex-grow: 1;
    width: auto;
}
.multi-step-item.complete .multi-step-icon {
    background-color: $palette-green-300;
}
.multi-step-item.complete .multi-step-title,
.multi-step-item.complete .multi-step-divider,
.multi-step-item.active .multi-step-divider,
.multi-step-item.active .multi-step-title {
    color: $palette-black-0;
}
.multi-step-item.active .multi-step-icon {
    background-color: $palette-white-0;
    color: $palette-black-0;
    border: 1px solid $palette-black-0;
}
.step_actions {
    width: 13%;
    margin-right: 20px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
}
.back-button,
.next-button {
    margin-right: 5px;
}
hr {
    width: 100%;
    height: 0px;
    border: 0.8px solid $palette-white-400;
    margin: 0;
}
