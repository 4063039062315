$nav-font-size: null !default;

// .nav-link

$nav-link-padding-x: 1rem !default;
$nav-link-padding-y: 0.5rem !default;

$nav-link-disabled-color: $gray-600 !default;
$nav-link-disabled-cursor: $disabled-cursor !default;

$nav-link: () !default;
$nav-link: map-deep-merge(
	(
		display: block,
		padding-bottom: $nav-link-padding-y,
		padding-left: $nav-link-padding-x,
		padding-right: $nav-link-padding-x,
		padding-top: $nav-link-padding-y,
		position: relative,
		hover: (
			text-decoration: none,
		),
		focus: (
			text-decoration: none,
			z-index: 1,
		),
		disabled: (
			color: $nav-link-disabled-color,
			cursor: $nav-link-disabled-cursor,
			active: (
				pointer-events: none,
			),
		),
	),
	$nav-link
);

// .nav-link.btn-unstyled

$nav-link-btn-unstyled: () !default;
$nav-link-btn-unstyled: map-deep-merge(
	(
		width: 100%,
		disabled-opacity: 1,
		c-inner: (
			width: auto,
		),
	),
	$nav-link-btn-unstyled
);

$nav-item-monospaced-size: 2rem !default; // 32px

// .nav-btn

/// @deprecated after v3.4.0 use the Sass map `$nav-btn` instead

$nav-btn-margin-x: 0.25rem !default; // 4px

/// @deprecated after v3.4.0 use the Sass map `$nav-btn` instead

$nav-btn-margin-y: calc(
	(
			(#{$line-height-base} * #{$font-size-base}) +
				(#{$nav-link-padding-y} * 2) - #{$nav-item-monospaced-size}
		) * 0.5
) !default;

/// @deprecated after v3.4.0 use the Sass map `$nav-btn` instead

$nav-btn-padding-x: 0.5rem !default;

/// @deprecated after v3.4.0 use the Sass map `$nav-btn` instead

$nav-btn-padding-y: 0 !default;

$nav-btn: () !default;
$nav-btn: map-deep-merge(
	(
		align-items: center,
		display: flex,
		height: $nav-item-monospaced-size,
		justify-content: center,
		line-height: $line-height-base,
		margin: $nav-btn-margin-y $nav-btn-margin-x,
		min-width: $nav-item-monospaced-size,
		padding: $nav-btn-padding-y $nav-btn-padding-x,
		position: relative,
		text-align: center,
		width: auto,
		focus: (
			z-index: 1,
		),
		disabled: (
			opacity: 1,
		),
		c-inner: (
			margin-bottom: 0,
			margin-left: math-sign($nav-btn-padding-x),
			margin-right: math-sign($nav-btn-padding-x),
			margin-top:
				math-sign(
					if(
						variable-exists(btn-border-width),
						$btn-border-width,
						$border-width
					)
				),
		),
		btn-link: (
			margin-left: 0,
			margin-right: 0,
		),
		lexicon-icon: (
			margin-top: 0,
		),
	),
	$nav-btn
);

// .nav-btn-monospaced

$nav-btn-monospaced: () !default;
$nav-btn-monospaced: map-deep-merge(
	(
		padding: 0,
		c-inner: (
			margin-left:
				math-sign(
					if(
						variable-exists(btn-border-width),
						$btn-border-width,
						$border-width
					)
				),
			margin-right:
				math-sign(
					if(
						variable-exists(btn-border-width),
						$btn-border-width,
						$border-width
					)
				),
		),
	),
	$nav-btn-monospaced
);

// .nav-link-monospaced

$nav-link-monospaced: () !default;
$nav-link-monospaced: map-deep-merge(
	(
		align-items: center,
		display: flex,
		height: $nav-item-monospaced-size,
		justify-content: center,
		margin: $nav-btn-margin-y $nav-btn-margin-x,
		min-width: $nav-item-monospaced-size,
		padding: 0,
		lexicon-icon: (
			margin-top: 0,
		),
	),
	$nav-link-monospaced
);

// .nav-item

$nav-item: () !default;
$nav-item: map-deep-merge(
	(
		word-wrap: break-word,
	),
	$nav-item
);

// .nav-divider

$nav-divider-color: $gray-600 !default;
$nav-divider-margin-y: 0.5rem !default;

$nav-divider: () !default;
$nav-divider: map-deep-merge(
	(
		margin-left: 0.5rem,
		padding-left: 0.5rem,
		position: relative,
		before: (
			background-color: $nav-divider-color,
			content: '',
			display: block,
			height: 1rem,
			left: 0,
			margin-top: -0.5rem,
			position: absolute,
			top: 50%,
			width: 1px,
			z-index: 2,
		),
	),
	$nav-divider
);

$nav-divider-end: () !default;
$nav-divider-end: map-deep-merge(
	(
		margin-right: 0.5rem,
		padding-right: 0.5rem,
		position: relative,
		after: (
			background-color: $nav-divider-color,
			content: '',
			display: block,
			height: 1rem,
			margin-top: -0.5rem,
			position: absolute,
			right: 0,
			top: 50%,
			width: 1px,
			z-index: 2,
		),
	),
	$nav-divider-end
);

// .nav-text-truncate

$nav-text-truncate: () !default;
$nav-text-truncate: map-merge(
	(
		display: inline-block,
		margin-bottom: -6px,
		max-width: 100%,
		overflow: hidden,
		text-overflow: ellipsis,
		white-space: nowrap,
	),
	$nav-text-truncate
);

// .nav .nav-form

$nav-form-padding-bottom: 0 !default;
$nav-form-padding-left: $nav-link-padding-x !default;
$nav-form-padding-right: $nav-link-padding-x !default;
$nav-form-padding-top: 0 !default;

// .nav

$nav: () !default;
$nav: map-deep-merge(
	(
		display: flex,
		flex-wrap: wrap,
		font-size: $nav-font-size,
		list-style: none,
		margin-bottom: 0,
		padding-left: 0,
		nav-form: (
			padding-bottom: $nav-form-padding-bottom,
			padding-left: $nav-form-padding-left,
			padding-right: $nav-form-padding-right,
			padding-top: $nav-form-padding-top,
		),
	),
	$nav
);

// .nav-fill

$nav-fill: () !default;
$nav-fill: map-deep-merge(
	(
		nav-item: (
			flex: 1 1 auto,
			text-align: center,
		),
	),
	$nav-fill
);

// .nav-justified

$nav-justified: () !default;
$nav-justified: map-deep-merge(
	(
		nav-item: (
			flex-basis: 0,
			flex-grow: 1,
			text-align: center,
		),
		nav-link: (
			text-align: center,
			width: 100%,
		),
	),
	$nav-justified
);

// .nav-stacked

$nav-stacked-nav-form-padding-bottom: $nav-link-padding-y !default;
$nav-stacked-nav-form-padding-left: 0.5rem !default;
$nav-stacked-nav-form-padding-right: 0.5rem !default;
$nav-stacked-nav-form-padding-top: $nav-link-padding-y !default;

$nav-stacked: () !default;
$nav-stacked: map-deep-merge(
	(
		display: block,
		nav-form: (
			padding-bottom: $nav-stacked-nav-form-padding-bottom,
			padding-left: $nav-stacked-nav-form-padding-left,
			padding-right: $nav-stacked-nav-form-padding-right,
			padding-top: $nav-stacked-nav-form-padding-top,
		),
	),
	$nav-stacked
);

// .nav-unstyled

$nav-unstyled: () !default;
$nav-unstyled: map-deep-merge(
	(
		flex-wrap: nowrap,
		nav-link: (
			line-height: $nav-item-monospaced-size,
			padding-bottom: 0,
			padding-left: 4px,
			padding-right: 4px,
			padding-top: 0,
		),
		nav-link-monospaced: (
			margin: 0 4px,
		),
		nav-btn: (
			margin: 0 4px,
			padding-bottom: 0,
			padding-left: 4px,
			padding-right: 4px,
			padding-top: 0,
		),
	),
	$nav-unstyled
);

// Nav Nested

$nav-nested-margins-spacer-x: $nav-link-padding-x !default;
$nav-nested-spacer-x: $nav-link-padding-x !default;

// Nav Tabs

$nav-tabs-font-size: null !default;

// Nav Tabs Link

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius $border-radius 0 0 !default;

$nav-tabs-link-color: null !default;
$nav-tabs-link-padding-x: null !default;
$nav-tabs-link-padding-y: null !default;

$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;

$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;
$nav-tabs-link-active-color: $gray-700 !default;

$nav-tabs-link-show-color: $nav-tabs-link-active-color !default;
$nav-tabs-link-show-bg: $nav-tabs-link-active-bg !default;
$nav-tabs-link-show-border-color: $nav-tabs-link-active-border-color !default;

// .nav-tabs .nav-link[aria-expanded="true"]

$nav-tabs-link-show: () !default;
$nav-tabs-link-show: map-deep-merge(
	(
		background-color: $nav-tabs-link-show-bg,
		border-color: $nav-tabs-link-show-border-color,
		color: $nav-tabs-link-show-color,
	),
	$nav-tabs-link-show
);

$nav-tabs-link: () !default;
$nav-tabs-link: map-deep-merge(
	(
		border-color: transparent,
		border-style: solid,
		border-width: $nav-tabs-border-width,
		border-radius: $nav-tabs-border-radius,
		color: $nav-tabs-link-color,
		padding-bottom: $nav-tabs-link-padding-y,
		padding-left: $nav-tabs-link-padding-x,
		padding-right: $nav-tabs-link-padding-x,
		padding-top: $nav-tabs-link-padding-y,
		white-space: nowrap,
		hover: (
			border-color: $nav-tabs-link-hover-border-color,
		),
		focus: (
			border-color: $nav-tabs-link-hover-border-color,
		),
		active: (
			background-color: $nav-tabs-link-active-bg,
			border-color: $nav-tabs-link-active-border-color,
			color: $nav-tabs-link-active-color,
		),
		show: $nav-tabs-link-show,
		disabled: (
			background-color: transparent,
			border-color: transparent,
			color: $nav-link-disabled-color,
		),
	),
	$nav-tabs-link
);

// .nav-tabs

$nav-tabs: () !default;
$nav-tabs: map-deep-merge(
	(
		border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color,
		font-size: $nav-tabs-font-size,
		nav-item: (
			margin-bottom: math-sign($nav-tabs-border-width),
		),
		nav-link: $nav-tabs-link,
	),
	$nav-tabs
);

// Nav Tabs Tab Pane

$nav-tabs-tab-pane-bg: null !default;
$nav-tabs-tab-pane-border-radius: null !default;
$nav-tabs-tab-pane-padding: null !default;

// Nav Pills

/// @deprecated as of v3.x with no replacement

$nav-pills-border-radius: $border-radius !default;

/// @deprecated as of v3.x with no replacement

$nav-pills-link-active-bg: $component-active-bg !default;

/// @deprecated as of v3.x with no replacement

$nav-pills-link-active-color: $component-active-color !default;

// Nav Underline Link Highlight

$nav-underline-link-highlight-content: null !default;
$nav-underline-link-highlight-height: null !default;
$nav-underline-link-highlight-bottom: 0 !default;
$nav-underline-link-highlight-left: $nav-link-padding-x * 0.5 !default;
$nav-underline-link-highlight-right: $nav-link-padding-x * 0.5 !default;
$nav-underline-link-highlight-top: null !default;

$nav-underline-link-active-highlight: $primary-l0 !default;
$nav-underline-link-active-content: '' !default;
$nav-underline-link-active-highlight-height: 0.1875rem !default;

$nav-underline-link-disabled-highlight: null !default;

// .nav-underline

$nav-underline-font-size: null !default;

$nav-underline-link-highlight-palette: null !default;

$nav-underline-link-color: null !default;
$nav-underline-link-padding-x: null !default;
$nav-underline-link-padding-y: null !default;

$nav-underline-link-hover-color: null !default;

$nav-underline-link-active-color: null !default;

$nav-underline-link-disabled-color: null !default;

// .nav-underline .nav-link[aria-expanded='true']

$nav-underline-link-show: () !default;
$nav-underline-link-show: map-deep-merge(
	(
		color: $nav-underline-link-active-color,
		after: (
			content: $nav-underline-link-active-content,
			height: $nav-underline-link-active-highlight-height,
		),
	),
	$nav-underline-link-show
);

$nav-underline-link: () !default;
$nav-underline-link: map-deep-merge(
	(
		color: $nav-underline-link-color,
		padding-bottom: $nav-underline-link-padding-y,
		padding-left: $nav-underline-link-padding-x,
		padding-right: $nav-underline-link-padding-x,
		padding-top: $nav-underline-link-padding-y,
		after: (
			bottom: $nav-underline-link-highlight-bottom,
			content: $nav-underline-link-highlight-content,
			display: block,
			height: $nav-underline-link-highlight-height,
			position: absolute,
			left: $nav-underline-link-highlight-left,
			right: $nav-underline-link-highlight-right,
			top: $nav-underline-link-highlight-top,
			width: auto,
		),
		hover: (
			color: $nav-underline-link-hover-color,
		),
		focus: (
			color: $nav-underline-link-hover-color,
		),
		active-class: (
			color: $nav-underline-link-active-color,
			after: (
				background-color: $nav-underline-link-active-highlight,
				content: $nav-underline-link-active-content,
				height: $nav-underline-link-active-highlight-height,
			),
		),
		show: $nav-underline-link-show,
		disabled: (
			color: $nav-underline-link-disabled-color,
			after: (
				background-color: $nav-underline-link-disabled-highlight,
			),
		),
	),
	$nav-underline-link
);

// .nav-underline

$nav-underline: () !default;
$nav-underline: map-deep-merge(
	(
		font-size: $nav-underline-font-size,
		nav-link: $nav-underline-link,
	),
	$nav-underline
);
