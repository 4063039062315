.ecx-dashboard {
    .panel-header {
        font-size: 1rem;
        border-bottom: 1px $palette-grey-300 solid;
        &.show {
            color: $palette-blue-0;
            border-bottom: none;
        }
        &.collapsed {
            border-color: $palette-grey-300;
        }
    }
    .learning-section {
        .besa-section {
            .collapse-icon-closed,
            .collapse-icon-open {
                display: none;
            }
            .panel-header {
                border-color: transparent;
            }
        }
    }

    a:link,
    a:visited {
        text-decoration: none;
    }
}
.la-card-header {
    font-size: 16px;
    line-height: 22px;
    color: #272b30;
    font-family: "Gotham Medium";
}
.la-card-table-info {
    font-size: 12px;
    line-height: 18px;
    color: rgba(106, 113, 120, 1);
}
.small-text {
    font-size: 12px !important;
}
