.brdcm-standard-footer {
    font-family: $primary-font, $secondary-font;
    background-color: $palette-white-1;
    margin-top: auto;

    @include media_query("tablet-landscape") {
        background-color: $palette-grey-475;
    }

    .brdcm-standard-container {
        display: flex;
        flex-direction: column-reverse;
        max-width: 1170px;

        @include media_query("tablet-portrait") {
            flex-direction: column;
        }

        @include media_query("tablet-landscape") {
            flex-direction: row;
            max-width: 960px;
        }

        @include media_query("desktop") {
            max-width: 1170px;
        }

        @include media_query("desktop-wide") {
            max-width: 1170px;
        }

        .brdcm-footer-segment-one {
            flex-basis: 66.67%;
            color: $palette-white-400;
            position: relative;

            @include media_query("tablet-landscape") {
                background-color: $palette-white-500;

                &::before {
                    content: "";
                    background-color: $palette-white-500;
                    width: 100vw;
                    height: 100%;
                    position: absolute;
                    right: 100%;
                }
            }

            @include media_query("tablet-portrait", "max") {
                padding-bottom: 50px;
            }

            .brdcm-footer-primary-nav {
                .brdcm-footer-primary-nav-links {
                    display: flex;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;

                    @include media_query("phone") {
                        flex-direction: row;
                    }

                    @include media_query("tablet-landscape") {
                        justify-content: flex-start;
                    }

                    .brdcm-footer-primary-nav-item {
                        list-style: none;
                        margin: 0 10px 0 0;
                        padding: 15px 0;

                        .brdcm-footer-primary-nav-link {
                            font-family: "Gotham hco";
                            text-decoration: none;
                            color: $palette-grey-550;
                            font-size: 14px;
                            font-weight: 500;
                            &:hover {
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }

            .brdcm-footer-copyright {
                font-size: 12px;
                color: $palette-grey-550;
                text-align: center;
                font-family: "arial w01 regular903292", sans-serif;

                @include media_query("tablet-landscape") {
                    text-align: left;
                }
            }

            .brdcm-footer-secondary-nav-links {
                margin: 0;
                display: flex;
                padding: 0;
                font-size: 12px;
                justify-content: center;
                flex-wrap: wrap;
                font-family: "arial w01 regular903292", sans-serif;

                .brdcm-footer-secondary-nav-item {
                    list-style: none;
                    margin: 10px 10px 10px 0;

                    .brdcm-footer-secondary-nav-link {
                        text-decoration: none;
                        color: $palette-grey-550 !important;
                    }
                }

                @include media_query("tablet-landscape") {
                    justify-content: left;
                }
            }
        }

        .brdcm-footer-segment-two {
            flex-basis: 33.34%;
            //background-color: rgb(135,135,135);

            .brdcm-footer-social-nav-links {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content: center;
                height: 100%;
                align-items: center;

                .brdcm-footer-social-nav-item {
                    list-style: none;

                    a {
                        // margin: 0 10px;
                        border-radius: 50%;
                        width: 36px;
                        height: 36px;
                        margin: 0 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $palette-grey-550;

                        &[href*="facebook.com"]:hover {
                            background-color: $palette-fb;
                        }

                        &[href*="twitter.com"]:hover {
                            background-color: $palette-twitter;
                        }

                        &[href*="linkedin.com"]:hover {
                            background-color: $palette-linkedin;
                        }

                        &[href*="youtube.com"]:hover {
                            background-color: $palette-youtube;
                        }

                        svg {
                            height: 20px;
                            vertical-align: middle;
                        }

                        @include media_query("tablet-portrait") {
                            color: $palette-white-0;
                        }
                    }
                }
            }
        }
    }
}

// footer {
//     @include media_query('tablet-landscape', max) {
//         display: none;
//     }
// }
