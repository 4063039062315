/*
    ---------------------------MIXIN REFERNCES---------------------------

    1) https://engageinteractive.co.uk/blog/top-10-scss-mixins

*/

// Make your content center align, don't worry about top and bottom margin
@mixin push--auto {
    margin: {
        left: auto;
        right: auto;
    }
}

//pseudo ::after ::before
@mixin pseudo($display: block, $pos: absolute, $content: "") {
    content: $content;
    display: $display;
    position: $pos;
}

//for default font
@mixin default-header-font($size: false, $colour: false, $weight: false, $lh: false) {
    font-family: $primary-header-font, $secondary-font;
    @if $size {
        font-size: $size;
    }
    @if $colour {
        color: $colour;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $lh {
        line-height: $lh;
    }
}

@mixin default-body-font($size: false, $colour: false, $weight: false, $lh: false) {
    font-family: $primary-body-font, $secondary-font;
    @if $size {
        font-size: $size;
    }
    @if $colour {
        color: $colour;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $lh {
        line-height: $lh;
    }
}
