.brdcm-modal-dropdown-wrapper {
    position: relative;

    .brdcm-modal-dropdown {
        display: none;
        position: absolute;

        .brdcm-modal-dropdown-view {
            min-width: 10rem;
            padding: 0.5rem 1rem;
            font-size: 1rem;
            color: #212529;
            text-align: left;
            list-style: none;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 0.25rem;
        }
    }
}

.brdcm-modal-show {
    .brdcm-modal-dropdown {
        display: block;
        top: 24px;
        left: 50%;
        transform: translateX(-50%);

        @include media_query("tablet-landscape") {
            right: 0px;
            left: auto;
            transform: unset;
        }
    }
}
