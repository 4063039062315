.table-responsive {
    margin: 0px !important;
    .table-list {
        border-collapse: collapse;
        margin: 0px !important;
        border: none;
        border-bottom: 1px solid $palette-grey-200;
        border-radius: 0px;
        thead {
            font-size: $text-lg;
            font-weight: 500;
            line-height: 1.5rem;
            tr {
                border-bottom: 2px solid $palette-blue-0;
                font-size: $text-size;
            }
        }
        tbody {
            tr {
                line-height: 3rem;
                td,
                th {
                    font-size: $text-md;
                    padding-bottom: 0px;
                    padding-top: 0px;
                    background-color: $palette-white-0 !important;
                }
            }
        }
        tfoot {
            th,
            td {
                background-color: $palette-white-0 !important;
            }
        }
    }
}
