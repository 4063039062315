.sticker {
	@include clay-sticker-variant($sticker);
}

.sticker-img {
	@extend %aspect-ratio-item-center-middle !optional;
	@extend %aspect-ratio-item-fluid !optional;
}

.sticker-overlay {
	@include clay-css($sticker-overlay);
}

// Sticker Positions

.sticker-bottom-left {
	@include clay-css($sticker-bottom-left);
}

.sticker-bottom-right {
	@include clay-css($sticker-bottom-right);
}

.sticker-top-left {
	@include clay-css($sticker-top-left);
}

.sticker-top-right {
	@include clay-css($sticker-top-right);
}

.sticker-outside {
	@include clay-css($sticker-outside);

	&.sticker-bottom-left {
		@include clay-css(map-get($sticker-outside, sticker-bottom-left));
	}

	&.sticker-bottom-right {
		@include clay-css(map-get($sticker-outside, sticker-bottom-right));
	}

	&.sticker-top-right {
		@include clay-css(map-get($sticker-outside, sticker-top-right));
	}
}

// Sticker User Icon

.sticker-user-icon {
	@include clay-sticker-variant($sticker-user-icon);
}

// Sticker Sizes

@each $selector, $value in $sticker-sizes {
	$selector: if(
		starts-with($selector, '.') or
			starts-with($selector, '#') or
			starts-with($selector, '%'),
		$selector,
		str-insert($selector, '.', 1)
	);

	@if (starts-with($selector, '%') or map-get($value, extend)) {
		#{$selector} {
			@include clay-sticker-variant($value);
		}
	} @else {
		$placeholder: if(
			starts-with($selector, '.') or starts-with($selector, '#'),
			'%#{str-slice($selector, 2)}',
			'%#{$selector}'
		);

		#{$placeholder} {
			@include clay-sticker-variant($value);
		}

		#{$selector} {
			@extend #{$placeholder} !optional;
		}
	}
}

// Sticker Variants

@each $color, $value in $sticker-palette {
	$selector: if(
		starts-with($color, '.') or
			starts-with($color, '#') or
			starts-with($color, '%'),
		$color,
		str-insert($color, '.sticker-', 1)
	);

	@if (starts-with($color, '%') or map-get($value, extend)) {
		#{$selector} {
			@include clay-sticker-variant($value);
		}
	} @else {
		$placeholder: if(
			starts-with($color, '.') or starts-with($color, '#'),
			'%#{str-slice($color, 2)}',
			'%sticker-#{$color}'
		);

		#{$placeholder} {
			@include clay-sticker-variant($value);
		}

		#{$selector} {
			@extend #{$placeholder} !optional;
		}
	}
}

// Sticker Circle

.sticker-circle {
	@include clay-sticker-variant($sticker-circle);
}
