/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    transition: background-color 5000s ease-in-out 0s;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
}

label[for] {
    font-size: $text-base-size;
    font-weight: 400;
}

//input fields
// input.form-control,
// select.form-control{
.form-control,
.input-group-inset {
    height: 2.5rem;
    box-shadow: none !important;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    color: $palette-grey-700;
    border-radius: 4px;
    border: 0.1rem solid $palette-grey-300;

    &:hover {
        border-color: $palette-grey-400;
    }

    &.focus,
    &:focus,
    &:focus:hover {
        border-color: $palette-blue-0;
    }

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $palette-grey-500;
        opacity: 1;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $palette-grey-500;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $palette-grey-500;
    }
}

// input fields disabled
// input.form-control:disabled,
// input.form-control.disabled{
.form-control:disabled,
.form-control.disabled {
    background: $palette-grey-0;
    color: $palette-grey-500;

    ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $palette-grey-500;
        opacity: 1;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $palette-grey-500;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $palette-grey-500;
    }
}

// Error fields
.has-error .form-control {
    border-color: $palette-red-100;

    &.focus,
    &:focus,
    &:focus:hover,
    &:hover {
        border-color: $palette-red-100;
    }
}

.has-error .form-feedback-item {
    font-size: 0.875rem;
    color: $palette-red-100;
    line-height: 1.3rem;
    font-weight: 400;
}

.has-error select.form-control {
    background-position: right 0.75rem center;
    background-repeat: no-repeat;
    background-size: 8px 10px;
}

//input field with icon
.input-group-prepend {
    .input-group-text {
        height: 2.5rem;
        border-width: 0.1rem;
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        background: $palette-white-0;
        border-right: none;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &.hasHover {
            border-color: $palette-grey-400;
        }

        &.hasFocus,
        &.hasFocus.hasHover {
            border-color: $palette-blue-0;
        }
    }

    & + .input-group-append {
        .form-control {
            border-left: none;
            padding-left: 0rem;
        }
    }
}

.input-group {
    .input-group-inset {
        &:hover ~ .input-group-inset-item {
            border-color: $palette-grey-400;
        }

        &:focus ~ .input-group-inset-item {
            border-color: $palette-blue-0;
        }
    }

    .input-group-inset-item {
        border-width: 0.1rem 0.1rem 0.1rem 0;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
}

.form-control[type="file"] {
    padding: 0;
}
