.scrollBtn {
    position: fixed;
    left: 90%;
    bottom: 40px;
    height: 40px;
    z-index: 1;
    cursor: pointer;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4;
    z-index: 2147483644;
    border-radius: 4px;
    border: 1.5px solid #6a7178;
    opacity: 0.8;
}

.scrollBtn:hover {
    opacity: 1;
}

.scrollBtnContents {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 10px;
}
