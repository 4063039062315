.global-loader-wrapper {
    position: fixed;
    // position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $white-opt-7;
    z-index: 9999;

    .global-loader-icon {
        position: fixed;
        // position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
    }
}
