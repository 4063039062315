// @import '../property_definitions/common.scss';

.btn {
    padding: 0.5rem 1rem;
    box-shadow: none !important;
    opacity: 1;
    &.btn-primary {
        background: $btn-primary-bg;
        border: 1px solid $btn-primary-bg;
        border-radius: $border-radius-md;
        @include default-body-font($text-md, $btn-primary-color, 500, 1.4);
        &-sm {
            background: $btn-primary-bg;
            border: 1px solid $btn-primary-bg;
            border-radius: $border-radius-md;
            @include default-body-font($text-sm, $btn-primary-color, 500, 1.2);
            padding: 0.4rem 0.5rem;
            &:hover,
            &:active,
            &:focus {
                opacity: 0.7;
            }
        }
        &:hover,
        &:active,
        &:focus {
            background: $btn-primary-bg;
            border: 1px solid $btn-primary-color;
            color: $btn-primary-color;
        }
    }

    &.btn-secondary {
        background: $btn-primary-color;
        border: 1.5px solid $btn-secondary-color;
        border-radius: $border-radius-md;
        @include default-body-font($text-md, $btn-secondary-color, 500, 1.4);
        &-sm {
            background: $btn-primary-color;
            border: 1.5px solid $btn-secondary-color;
            border-radius: $border-radius-md;
            @include default-body-font($text-sm, $btn-secondary-color, 500, 1.2);
            padding: 0.4rem 0.5rem;
            &:hover,
            &:active,
            &:focus {
                opacity: 0.7;
            }
        }
        &:hover,
        &:active,
        &:focus {
            background: $btn-primary-color;
            border: 1px solid $btn-secondary-color;
            color: $btn-secondary-color;
        }
    }

    &.btn-cancel {
        background: $btn-primary-color;
        border: 1.5px solid $border-color-secondary;
        border-radius: $border-radius-md;
        @include default-body-font($text-md, $palette-grey-500, 500, 1.4);
        &-sm {
            background: $btn-primary-color;
            border: 1.5px solid $border-color-secondary;
            border-radius: $border-radius-md;
            @include default-body-font($text-sm, $palette-grey-500, 500, 1.2);
            padding: 0.4rem 0.5rem;
        }
        &:hover,
        &:active,
        &:focus {
            background: $btn-primary-color;
            border: 1.5px solid $border-color-secondary;
            color: $palette-grey-500;
        }
    }

    &.btn-disabled {
        background: $btn-disabled-bg;
        border: 1.5px solid $palette-grey-200;
        border-radius: $border-radius-md;
        @include default-body-font($text-md, $btn-disabled-color, 500, 1.4);
        &-sm {
            background: $btn-disabled-bg;
            border: 1.5px solid $palette-grey-200;
            border-radius: $border-radius-md;
            @include default-body-font($text-sm, $btn-disabled-color, 500, 1.2);
            padding: 0.4rem 0.5rem;
        }
        &:hover,
        &:active,
        &:focus {
            background: $btn-disabled-bg;
            border: 1.5px solid $palette-grey-200;
            color: $btn-disabled-color;
        }
    }

    &.btn-success {
        background: $color-success;
        border: 1.5px solid $color-success;
        border-radius: $border-radius-md;
        @include default-body-font($text-md, $btn-primary-color, 500, 1.4);
        &-sm {
            background: $color-success;
            border: 1.5px solid $color-success;
            border-radius: $border-radius-md;
            @include default-body-font($text-sm, $btn-primary-color, 500, 1.2);
            padding: 0.4rem 0.5rem;
        }
        &:hover,
        &:active,
        &:focus {
            background: $color-success;
            border: 1.5px solid $color-success;
            color: $btn-primary-color;
        }
    }

    &.btn-error {
        background: $color-error;
        border: 1.5px solid $color-error;
        border-radius: $border-radius-md;
        @include default-body-font($text-md, $btn-primary-color, 500, 1.4);
        &-sm {
            background: $color-error;
            border: 1.5px solid $color-error;
            border-radius: $border-radius-md;
            @include default-body-font($text-sm, $btn-primary-color, 500, 1.2);
            padding: 0.4rem 0.5rem;
        }
        &:hover,
        &:active,
        &:focus {
            background: $color-error;
            border: 1.5px solid $color-error;
            color: $btn-primary-color;
        }
    }

    &.btn-link {
        border: 0;
        background: transparent;
        @include default-body-font($text-md, $btn-primary-color, 500, 1.4);
        &-sm {
            border: 0;
            background: transparent;
            @include default-body-font($text-sm, $btn-primary-color, 500, 1.2);
        }
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
        }
    }

    &.btn-link-text {
        border: 0;
        background: transparent;
        @include default-body-font($text-md, $btn-secondary-color, 700, 1.4);
        &-sm {
            border: 0;
            background: transparent;
            @include default-body-font($text-sm, $btn-secondary-color, 500, 1.2);
        }
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            @include default-body-font($text-md, $btn-secondary-color, 700, 1.4);
        }
    }

    &.btn-transparent {
        background: transparent;
        border: 1.5px solid $btn-primary-color;
        border-radius: $border-radius-md;
        @include default-body-font($text-md, $btn-primary-color, 500, 1.4);
        &-sm {
            background: transparent;
            border: 1.5px solid $btn-primary-color;
            border-radius: $border-radius-md;
            @include default-body-font($text-sm, $btn-primary-color, 500, 1.2);
            padding: 0.4rem 0.5rem;
        }
        &:hover,
        &:active,
        &:focus {
            background: transparent;
            border: 1.5px solid $btn-primary-color;
            color: $btn-primary-color;
        }
    }

    &.btn-search-icon {
        background: $btn-primary-color;
        border: 1.5px solid $palette-grey-300;
        border-radius: $border-radius-md;
        padding: 0.5rem;
        &:hover,
        &:active,
        &:focus {
            background: $btn-primary-color;
            border: 1.5px solid $palette-grey-300;
            border-radius: $border-radius-md;
        }
    }

    &.btn-cancel-icon {
        background: $palette-grey-100;
        border: 0;
        border-radius: $border-radius-md;
        padding: 0.5rem;
        &:hover,
        &:active,
        &:focus {
            background: $palette-grey-100;
            border: 0;
        }
    }

    &.btn-info {
        background: $palette-blue-200;
        border: 1.5px solid $btn-secondary-color;
        border-radius: $border-radius-md;
        @include default-body-font($text-md, $btn-secondary-color, 500, 1.4);
        &-sm {
            background: $palette-blue-200;
            border: 1.5px solid $btn-secondary-color;
            border-radius: $border-radius-md;
            @include default-body-font($text-sm, $btn-secondary-color, 500, 1.2);
            padding: 0.4rem 0.5rem;
        }
        &:hover,
        &:active,
        &:focus {
            background: $palette-blue-200;
            border: 1px solid $btn-secondary-color;
            color: $btn-secondary-color;
        }
    }

    &.btn-esp-scroll-top {
        display: inline-block;
        position: fixed;
        z-index: 2147483644;
        right: 40px;
        bottom: 1rem;
        border: 1.5px solid $palette-grey-500;
        padding: 9px 11px;
        line-height: 1.5;
        border-radius: 3px;
        background-color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
        @include default-body-font($text-md, $btn-primary-color, 500, 1.4);
        &:hover,
        &:active,
        &:focus {
            border: 1.5px solid $palette-grey-500;
            color: $btn-primary-color;
            background-color: rgba(0, 0, 0, 0.8);
            text-decoration: none;
        }
    }
}
