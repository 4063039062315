.forgot-password-modal .modal-dialog {
    height: 100%;
    margin: 0;
    max-width: 100%;

    .modal-content {
        margin: 0 auto;
        width: 50%;

        padding: 1rem 1rem 0.5rem;

        .modalHeader {
            border: none;
            height: 5rem;
            justify-content: center;
        }

        .modalBody {
            border: none;
            font-size: 1.2rem;
            padding: 1rem 1rem 1rem;
            text-align: center;
        }

        .modalFooter {
            border: none !important;

            .modal-item {
                align-items: center;

                .modalButton {
                    border: none !important;
                    padding: 0.5rem 1.3rem;
                }
            }
        }
    }
}

#emailInputText {
    background-color: #fff !important;
}