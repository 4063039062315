.brdcm-standard-header {
    font-family: $primary-font, $secondary-font;
    font-size: $text-size;

    .brdcm-container {
        @include media_query("tablet-landscape", max) {
            width: 100% !important;
        }
    }

    .brdcm-brand-logo {
        //background: url(https://static.broadcom.com/static/img/broadcom-logo.png) center center no-repeat;
        background: url(https://www.broadcom.com/img/broadcom-logo.png) center center no-repeat;
        font-size: 0;
        height: 30px;
        margin: 0;
        width: 205px;
    }

    .brdcm-header-top {
        background-color: $palette-white-1;
        box-sizing: border-box;
        display: flex;
        height: 90px;
        justify-content: space-between;
        position: relative;
        border-bottom: 0.5em solid #cc092f;

        .brdcm-navigation-primary {
            align-items: center;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;

            @include media_query("tablet-landscape") {
                flex-direction: row;
            }

            .brdcm-branding {
                min-width: 215px;
                margin: auto;

                .brdcm-brand-logo {
                    background-size: contain;
                    display: block;
                }
            }

            .brdcm-portal-name-container {
                position: relative;

                .brdcm-portal-name {
                    margin-right: 1.5em;
                    padding-top: 0.25em;

                    .support {
                        line-height: 1.1;
                        color: $tertiary-font-color;
                    }
                }
            }

            .brdcm-primary-nav-container {
                align-items: center;
                display: flex;
                position: absolute;
                left: 10px;
                width: 100%;

                @include media_query("tablet-landscape") {
                    flex: 1;
                    position: relative;
                }

                .brdcm-primary-menu {
                    text-align: center;

                    button {
                        background-color: transparent;
                        border: none;
                        padding: 0;
                        outline: 0;
                        cursor: pointer;

                        .brdcm-burger-bar {
                            border-bottom: 0.25em solid $primary;
                            width: 2em;
                        }

                        div {
                            &::after {
                                @include pseudo(block, relative);
                                @extend .brdcm-burger-bar;
                                margin: 0.25em auto;
                            }

                            @extend .brdcm-burger-bar;

                            &::before {
                                @include pseudo(block, relative);
                                @extend .brdcm-burger-bar;
                                margin: 0.3em auto;
                            }
                        }
                    }

                    .brdcm-primary-menu-label {
                        color: $primary;
                        text-transform: uppercase;
                        font-size: 0.75em;
                        font-weight: bold;
                    }
                }

                .brdcm-primary-nav-links-wrapper {
                    background-color: $hamburger-background;
                    display: none;
                    left: -10px;
                    position: absolute;
                    top: 50px;
                    width: 100vw;
                    z-index: 4;

                    &.brdcm-menu-open {
                        display: block;
                    }

                    @include media_query("tablet-landscape") {
                        background-color: transparent;
                        display: flex;
                        flex: 1;
                        position: relative;
                        top: 0;
                        width: auto;
                    }

                    .brdcm-primary-nav-links {
                        align-items: center;
                        display: flex;
                        flex-wrap: nowrap;
                        margin: 0;
                        padding: 0;

                        @include media_query("tablet-landscape", max) {
                            flex-direction: column;
                        }

                        .brdcm-primary-nav-item {
                            display: inline-block;
                            padding: 0.25em 0.5em;
                            position: relative;
                            //text-transform: uppercase;
                            width: auto;

                            .brdcm-primary-nav-link {
                                cursor: pointer;
                                display: inline-block;
                                padding: 0.75em 0;
                                text-decoration: none;
                                color: $palette-red-0;
                                font-weight: 400;

                                @include media_query("tablet-landscape", max) {
                                    background-color: $palette-white-1;
                                    padding: 0.6em;
                                    width: 100%;
                                    border-radius: 4px;
                                    margin-top: 0.5em;
                                    margin-bottom: 0.5em;
                                    min-width: 330px;
                                    text-align: center;
                                }
                            }
                            .brdcm-primary-menu {
                                display: none;
                                position: absolute;
                            }

                            &:hover {
                                background-color: $btn-secondary-color;
                                @include media_query("tablet-landscape") {
                                    background-color: transparent;
                                }

                                .brdcm-primary-nav-link {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .secondary_nav {
                        .login-wrap {
                            display: block;
                            color: $palette-white-0;
                            font-size: 16px;
                            background-color: $colorBg-1;
                            border-bottom: 2px solid $palette-green-1;
                            position: relative;
                            overflow: hidden;
                            margin-bottom: 10px;

                            .dropdown {
                                .dropdown-toggle {
                                    font-size: 16px;
                                    padding: 0 10px;
                                    width: 100%;
                                    text-align: left;
                                    line-height: 3;
                                    border: none;
                                    background: transparent;
                                    color: $palette-white-0;

                                    &::after {
                                        display: inline-block;
                                        margin-left: 0.255em;
                                        vertical-align: 0.255em;
                                        content: "";
                                        position: absolute;
                                        top: 50%;
                                        -moz-transform: translate(0, -50%);
                                        -webkit-transform: translate(0, -50%);
                                        transform: translate(0, -50%);
                                        right: 10px;
                                        border-top: 5px solid $palette-white-0;
                                        border-left: 5px solid transparent;
                                        border-right: 5px solid transparent;
                                    }
                                }

                                &.show {
                                    .dropdown-menu {
                                        position: relative;
                                        display: block;
                                        float: none;
                                        border: none;
                                        border-radius: 0;
                                        padding: 0.5em 1em;
                                        max-width: 100%;
                                    }
                                }
                            }
                        }

                        #sidebar-wrapper {
                            width: 100%;
                            background: transparent !important;
                            padding: 2rem;

                            .sidebar-nav {
                                display: flex;
                                width: 100%;
                                flex-wrap: wrap;
                                list-style-type: none;

                                .sidebar-brand {
                                    display: none !important;
                                }

                                li {
                                    flex-basis: 25%;
                                    flex-grow: 1;

                                    a {
                                        flex-direction: column;
                                        align-items: center;
                                        justify-content: center;

                                        .menu-section {
                                            display: block !important;
                                            width: 100% !important;
                                            text-align: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .brdcm-nav-logged-in-details {
                    margin-left: auto;

                    @include media_query("tablet-landscape") {
                        align-items: center;
                        display: flex;
                        margin-left: auto;
                    }

                    .brdcm-login-register-btn {
                        @include media_query("tablet-landscape", "max") {
                            display: none;
                        }
                    }

                    .brdcm-profile-container {
                        text-align: center;
                        padding: 0.25em 0.5em;
                        font-family: $primary-body-font, $secondary-font;

                        @include media_query("tablet-landscape") {
                            padding: 0;
                        }

                        $p: &;
                        button {
                            background: transparent;
                            border: none;
                            border-radius: 2.5px;
                            color: $secondary-font-color;
                            cursor: pointer;
                            line-height: 1.5;
                            padding: 0.75em;
                            position: relative;
                            text-transform: uppercase;
                            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
                                border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                            vertical-align: middle;
                            width: 100%;

                            @include media_query("tablet-landscape") {
                                color: $quaternary-font-color;
                                padding: 0 1.5em;
                            }

                            &::after {
                                border-bottom: 0;
                                border-left: 0.3em solid transparent;
                                border-right: 0.3em solid transparent;
                                border-top: 0.3em solid;
                                content: "";
                                display: inline-block;
                                font-size: 1em;
                                line-height: 1.5;
                                margin-left: 0.255em;
                                vertical-align: 0.255em;
                            }

                            &:hover {
                                background-color: $secondary-nav-background;
                                border-color: $border-color-secondary;
                                color: $secondary;
                            }
                        }

                        &.brdcm-modal-show {
                            button {
                                background-color: $secondary-nav-background;
                                border-color: $border-color-secondary;
                                color: $secondary;
                                opacity: 0.8;
                            }
                        }

                        .brdcm-header-login-modal {
                            white-space: nowrap;

                            .brdcm-header-modal-title {
                                font-size: 1em;
                            }

                            .brdcm-header-forgot-link {
                                font-size: 0.75em;
                                color: $tertiary-font-color;
                                text-decoration: none;
                                cursor: pointer;

                                &:hover {
                                    color: $link-default-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .brdcm-secondary-nav-links {
        margin: 0;
        padding: 0;

        .brdcm-secondary-nav-item {
            list-style: none;
            color: $secondary-font-color;
            text-transform: uppercase;

            .brdcm-secondary-nav-link {
                border-bottom: 0.1em solid $border-color-tertiary;
                cursor: pointer;
                color: $secondary-font-color;
                display: block;
                padding: 0.725em 1em;
                text-decoration: none;
                font-size: 0.725em;
            }

            @include media_query("tablet-landscape") {
                padding-top: 0.5em;
                padding-bottom: 0.5em;

                .brdcm-secondary-nav-link {
                    padding: 0.75em 1em;
                }
            }

            &:hover {
                background-color: $btn-secondary-color;

                .brdcm-secondary-nav-link {
                    text-decoration: underline;
                }
            }
        }

        @include media_query("tablet-landscape") {
            display: flex;

            .brdcm-secondary-nav-item {
                .brdcm-secondary-nav-link {
                    display: inline;
                    font-size: 1em;
                }

                &:hover {
                    background-color: inherit;
                }
            }
        }
    }

    .brdcm-header-bottom {
        background-color: $secondary-nav-background;
    }
}

.menu-width {
    min-width: 37rem !important;
}

.menu-height {
    min-height: 32rem !important;
}
