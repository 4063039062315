/*
    ---------------------------MIXIN REFERNCES---------------------------

    1) https://engageinteractive.co.uk/blog/top-10-scss-mixins

*/

/* Media queries */
$breakpoints: (
    "phone": 576px,
    "tablet-portrait": 768px,
    "tablet-landscape": 992px,
    "desktop": 1200px,
    "desktop-wide": 1440px,
    "desktop-xl": 1900px,
);

@mixin media_query($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media screen and (#{$type}-width: $width) {
            @content;
        }
    }
}
