.brdcm-btn-wrapper {
    display: flex;
}

.brdcm-btn {
    padding: 10px 12px;
    line-height: 1.5;
    border-radius: 4px;
    background-color: transparent;
    color: inherit;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;

    @at-root {
        a#{&} {
            text-decoration: none;
        }
    }
}

.btn-oval {
    border-radius: 25px;
    margin: 15px 15px 7px 0px;
}

.btn-primary {
    background-color: $btn-primary-color;
    color: $palette-white-0;

    &:hover {
        opacity: 0.7;
        background-color: $btn-secondary-color;
        color: $palette-white-0;
    }
}

.btn-secondary {
    background-color: $btn-secondary-color;
    color: $palette-white-0;
    opacity: 0.7;

    &:hover {
        opacity: 0.4;
        background-color: $btn-secondary-color;
        color: $palette-white-0;
    }
}
.btn {
    padding: 8px 12px;
}
