@import "../property_definitions/colors";
.navBar {
    background-color: $palette-grey-800;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    .nav-link {
        &.active {
            color: $palette-white-0;
            font-weight: bold;
            background-color: $palette-blue-0;
        }
        font-size: 1rem;
    }

    .nav-text {
        text-decoration: none;
        color: $palette-grey-480;
    }

    .nav-text:hover {
        color: $palette-white-0;
    }
}
.menu-bg-color {
    background-color: $palette-grey-700;
}
