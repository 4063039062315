// TYPOGRAPHY
$fontSize-html: 10px; // Root, pixel-based font-size to support rem-sizes (root-relative em).
$fontSize-body: 1.4rem; // Base rem-font-size for entire page.
$fontSize-small-medium: 1.3rem;
$fontSize-small: 1.2rem;
$fontSize-medium: 1.8rem;
$fontSize-h1: 2.4rem;
$fontSize-h2: 1.8rem;
$fontSize-h3: 1.6rem;
$fontSize-legend: 1.1rem;
$fontSize-pagination: 1.1rem;
$fontFamily-Gotham: "Gotham", Arial, Helvetica, sans-serif; // Non-header family.
$fontFamily-medium: "GothamMedium", Arial, Helvetica, sans-serif; // Non-header family.

$btn-font-weight: normal;

//BackGround
$colorBg-1: rgba(51, 51, 51, 1);

/* border Radius */
$border-radius: 0.25rem;
$border-radius-md: 0.3rem;
$border-radius-lg: 0.5rem;
