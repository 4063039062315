.card {
    border: none;
    box-shadow: 0px 4px 16px $palette-black-1;
    border-radius: 8px;
    .card-body {
        .card-desc {
            font-size: 14px;
            line-height: 21px;
            color: $palette-grey-600;
        }
        .card-header-txt {
            font-size: 16px;
            line-height: 22px;
            color: $palette-grey-700;
        }

        .card-footer-txt {
            font-size: 14px;
            line-height: 18px;
            color: $palette-grey-450;
        }
    }
}
