.search-panel {
    background-color: $palette-grey;
    padding: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 8px;
}

.btn-reset-search {
    color: #005c8a;
    font-weight: 600;
}

.rbt-highlight-text {
    background-color: inherit !important;
    color: inherit !important;
    font-weight: bold !important;
    padding: 0 !important;
    box-shadow: 0 0 #fcf8e3 !important;
    position: initial !important;
}
