$nav-font-size: 0.875rem !default;

// .nav-link

$nav-link-disabled-color: $gray-500 !default;

$nav-link-padding-x: 1rem !default; // 16px
$nav-link-padding-y: 0.625rem !default; // 10px

$nav-link: () !default;
$nav-link: map-deep-merge(
	(
		disabled: (
			box-shadow: none,
		),
	),
	$nav-link
);

// .nav-link.btn-unstyled

$nav-link-btn-unstyled: () !default;
$nav-link-btn-unstyled: map-deep-merge(
	(
		focus: (
			box-shadow: $component-focus-box-shadow,
		),
	),
	$nav-link-btn-unstyled
);

// Nav Nested

$nav-nested-spacer-x: 1rem !default; // 16px

// Nav Tabs

$nav-tabs-border-color: transparent !default;
$nav-tabs-font-size: 0.875rem !default; // 14px

// Nav Tabs Link

$nav-tabs-link-color: $gray-600 !default;
$nav-tabs-link-padding-y: 0.28125rem !default; // 4.5px

$nav-tabs-link-hover-border-color: transparent !default;

$nav-tabs-link-active-bg: $white !default;
$nav-tabs-link-active-border-color: transparent transparent $body-bg !default;
$nav-tabs-link-active-color: $gray-900 !default;

$nav-tabs-link-show-color: $nav-tabs-link-active-color !default;
$nav-tabs-link-show-bg: transparent !default;
$nav-tabs-link-show-border-color: transparent transparent $nav-tabs-border-color
	transparent !default;

$nav-tabs-link: () !default;
$nav-tabs-link: map-deep-merge(
	(
		font-weight: $font-weight-semi-bold,
		transition: box-shadow 0.15s ease-in-out,
		focus: (
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
		disabled: (
			box-shadow: none,
			active: (
				box-shadow: none,
			),
		),
	),
	$nav-tabs-link
);

// Nav Tabs Tab Pane

$nav-tabs-tab-pane-bg: $white !default;
$nav-tabs-tab-pane-border-radius: 4px !default;
$nav-tabs-tab-pane-padding: 2rem !default;

// Nav Underline Link Highlight

$nav-underline-link-highlight-left: 0 !default;
$nav-underline-link-highlight-right: 0 !default;

$nav-underline-link-active-highlight: $primary-l0 !default;
$nav-underline-link-active-highlight-height: 0.125rem !default; // 2px

// Nav Underline

$nav-underline-link-color: $gray-600 !default;

$nav-underline-link-active-color: $gray-900 !default;

$nav-underline-link-disabled-color: $nav-link-disabled-color !default;

$nav-underline-link: () !default;
$nav-underline-link: map-deep-merge(
	(
		border-radius: 1px,
		font-weight: $font-weight-semi-bold,
		line-height: 1,
		padding-bottom: 0.5625rem,
		padding-top: 0.5625rem,
		transition: box-shadow 0.15s ease-in-out,
		focus: (
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
		disabled: (
			box-shadow: none,
		),
	),
	$nav-underline-link
);
