// @import '../property_definitions/common.scss';

.pagination-bar {
    padding: 1rem !important;

    .pagination-items-per-page {
        border: 1px solid $palette-white-600;
        border-radius: 4px;
    }

    .pagination {
        .page-item {
            margin-left: 0.25rem;
            margin-right: 0.25rem;
            .page-link {
                border: 1px solid $palette-grey-300;
                background-color: $palette-white-0;
                color: $palette-grey-600;
                font-weight: normal;
                border-radius: 4px;
                height: 2rem;
            }
            .page-link:focus {
                box-shadow: none;
            }
            &:not(.disabled) {
                button {
                    cursor: pointer;
                }
            }
        }
        .page-item:first-child .page-link,
        .page-link-first {
            border-radius: 4px;
        }

        .page-item:last-child .page-link,
        .page-link-last {
            border-radius: 4px;
        }
        .page-item.active .page-link {
            border: 1px solid $palette-blue-0;
            color: $palette-blue-0;
        }
    }
    .pagination-results {
        font-size: $text-md;
    }
}

.pagination-top {
    font-size: 16px;
    line-height: 19px;
    color: $palette-grey-600;
}
