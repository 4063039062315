/*  order is important  */

@import "./vars";
@import "./fonts";
@import "./colors";
@import "./typography";
@import "./_mixins";
@import "./media_query";
@import "./visibility";
@import "./z-index";
@import "./theme_default.scss";

// ============================== GLOBALS ====================================
@import "./common/generic";
@import "./common/container";
@import "./common/modal-dropdown.scss";
@import "./common/button";
@import "./common/forminputs";
@import "./common//skeleton.scss";

body {
    @include default-body-font($text-base-size, $primary-font-color, 400, 1.5);
    overflow-x: hidden;
    -webkit-font-smoothing: auto;
}
h1,
.h1 {
    @include default-header-font($text-xl, $primary-header-color, 500, 2.18);
}

h2,
.h2 {
    @include default-header-font($text-lg, $secondary-header-color, 500, 1.35);
}

h3,
.h3 {
    @include default-header-font($text-base-size, $secondary-header-color, 500, 1.5);
}

h4,
.h4 {
    @include default-header-font($text-md, $secondary-header-color, 500, 1.3);
}

.title {
    @include default-body-font($text-base-size, $secondary-font-color, 400, 1);
}

.text-small {
    @include default-body-font($text-sm, $secondary-font-color, 400, 1.5);
}

.esp-container {
    @include default-body-font($text-md, $primary-font-color, 400, 1.5);
    background-color: $body-bg-color;
}

.default-nav-link {
    color: $link-default-color !important;
}

.pe-cursor {
    cursor: pointer;
}

.esp-rowx-n {
    margin-left: -1rem;
    margin-right: -1rem;
}

.text-size-sm {
    font-size: $text-sm !important;
}

.text-size-md {
    font-size: $text-md !important;
}

.text-size-lg {
    font-size: $text-lg !important;
}

.text-size-xl {
    font-size: $text-xl !important;
}

.text-size-xxl {
    font-size: $text-xxl !important;
}
.word-breal-all {
    word-break: break-all;
}

.text-red {
    color: $palette-red-100;
}

.link-on-hover {
    &:hover {
        color: $link-default-color;
    }
}

.esp-overflow-anywhere {
    overflow-wrap: anywhere;
}
.tooltip {
    z-index: $tooltip-z-index;
}

.form-feedback-item {
    font-size: $text-sm !important;
}
.panel-secondary {
    .panel-collapse.show {
        height: auto !important;
    }
}

.dashboard-top-tile-height {
    height: 280px;

    @include media_query("desktop") {
        height: 220px;
    }
}

.z-indexed-1200 {
    z-index: 1200 !important;
}
//TODO-NIK Added this..is that okay
.body-bg {
    background-color: $palette-grey-1;
}

.board-row:after {
    clear: both;
    content: "";
    display: table;
}

.status {
    margin-bottom: 10px;
}

.square {
    background: #fff;
    border: 1px solid #999;
    float: left;
    font-size: 24px;
    font-weight: bold;
    height: 34px;
    line-height: 34px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 0;
    text-align: center;
    width: 34px;
}

.square:focus {
    outline: none;
}

.kbd-navigation .square:focus {
    background: #ddd;
}

.game {
    display: flex;
    flex-direction: row;
}

.game-info {
    margin-left: 20px;
}

.game-info ol,
.game-info ul {
    padding-left: 30px;
}

@media only screen and (max-width: 992px) {
    .registration-page span.h1 {
        font-size: 1.2rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .registration-page span.h1 {
        font-size: 1.5rem;
    }
}
.registration-page span.h1 {
    font-size: 1.5rem;
}

html {
    font-size: 16px;
    @include media_query("desktop-wide", max) {
        font-size: 14px;
    }
}
