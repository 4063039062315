.text-align-start {
    text-align: start !important;
}
.containerCSS {
    background-color: $palette-white-0;
    text-align: center;
    margin-left: 1.6rem;
    width: 95%;
}
.pl-1 {
    padding-left: 1rem !important;
}
.bg-white {
    background-color: $palette-white-0;
}
.form-control-sm,
.form-group-sm .form-control,
.form-control-select.form-control-sm,
.form-group-sm .form-control-plaintext,
.form-group-sm select.form-control,
.input-group-sm .form-control {
    background-color: $palette-white-0;
}
.toggle-switch-check:checked ~ .toggle-switch-bar:before {
    background: #74b816;
    border: 1px solid #74b816;
}
.nav-underline .nav-link.active:after {
    background: #005c8a;
}
