@if ($enable-bs4-deprecated) {
	// Rotating border

	@keyframes spinner-border {
		to {
			transform: rotate(360deg);
		}
	}

	.spinner-border {
		animation: spinner-border 0.75s linear infinite;
		border-color: currentColor transparent currentColor currentColor;
		border-radius: 50%;
		border-style: solid;
		border-width: $spinner-border-width;
		display: inline-block;
		height: $spinner-height;
		vertical-align: text-bottom;
		width: $spinner-width;
	}

	.spinner-border-sm {
		border-width: $spinner-border-width-sm;
		height: $spinner-height-sm;
		width: $spinner-width-sm;
	}

	// Growing circle

	@keyframes spinner-grow {
		0% {
			transform: scale(0);
		}
		50% {
			opacity: 1;
		}
	}

	.spinner-grow {
		animation: spinner-grow 0.75s linear infinite;
		background-color: currentColor;
		border-radius: 50%;
		display: inline-block;
		height: $spinner-height;
		opacity: 0;
		vertical-align: text-bottom;
		width: $spinner-width;
	}

	.spinner-grow-sm {
		height: $spinner-height-sm;
		width: $spinner-width-sm;
	}
}
