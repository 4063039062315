@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.brdcm-fade-in {
    animation: fadeIn 0.5s;
}
