.alreadyExistsModal > .modal-dialog > .modal-content > .modal-body {
    border: none !important;
}

.cross-icon{
    font-size: 5rem;
    color: red;
    font-weight: 100;
    line-height: 0;
}
