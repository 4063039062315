.profile-login-section {
    width: 100%;
    @include default-body-font($text-size, $primary-font-color, 400, 1.5);

    &.profile-login-toolbar {
        @include media_query("tablet-portrait", "max") {
            position: fixed;
            z-index: 4;
            width: 100%;
            bottom: 0;
            left: 0;
        }
    }

    .bu-dropdown {
        border-right: 2px solid $palette-grey-400;
        .dropdown-item {
            padding-right: 0;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            padding-left: 4rem;
        }
        .dropdown-menu {
            width: 325px;
        }
        .dropdown-item-indicator-start {
            display: block;
        }

        .active-bu {
            background-color: rgb(198, 218, 225);
        }
        #ecx-bu-dropdown {
            .bu-disabled {
                pointer-events: none;
                opacity: 0.5;
            }
        }
    }
    .dropdown-menu {
        max-width: none;
        max-height: none;
        left: auto;
        font-size: inherit;
        margin-left: -100px;
        z-index: 1500;

        @include media_query("tablet-portrait", "max") {
            position: absolute;
            top: auto;
            bottom: 52px;

            &#ecx-notification-panel {
                left: 0;
                right: 0;
                margin-left: 0;
            }
        }
    }
    .dropdown-item {
        padding-right: 2rem;
    }
    .show-bu-name {
        height: 2.5rem;
        box-shadow: none !important;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        // padding-top: 0.3rem;
        padding-top: 0.2rem;
        /* color: #272b30; */
        border-radius: 4px;
        border: 0.1rem solid #ced4da;
        background-color: #fff;
    }

    .border-bottom-grey {
        border-bottom: 1px solid $palette-grey-200;
    }

    .user-info {
        .form-control:disabled,
        .form-control.disabled {
            background: transparent;
            cursor: auto;
            color: inherit;
        }
        .form-control[readonly] {
            background-color: transparent;
        }
        .disabled-field {
            background-color: #e9ecef !important;
            cursor: not-allowed;
        }
        label[for] {
            font-size: $text-md;
        }
    }
}
.header-bg {
    //background-color: #eeeeee;
    background-color: #f7f7f7;
}
.subscription-access-form {
    border: #dfd7d7 solid 1px !important;
    border-radius: 5px !important;
    .subscription-access-form-text {
        font-size: small;
        color: #857878;
        font-weight: 550;
        margin: 10px;
    }
    .subscription-access-form-toggle {
        margin-top: 5px !important;
    }
    .subscription-access-form-status {
        margin-top: 7px !important;
    }
}

.badge-Pending,
.badge-Draft {
    border-radius: 100px;
    background-color: $palette-orange-0;
    color: $palette-orange-100;
    padding: 1.5px 4px;
}

.badge-Approved,
.badge-Success {
    border-radius: 100px;
    background-color: $palette-green-0;
    color: $palette-green-100;
    padding: 1.5px 4px;
}

.badge-Rejected {
    border-radius: 100px;
    background-color: $palette-red-300;
    color: $palette-red-100;
    padding: 1.5px 4px;
}

.badge-Not-Requested {
    border-radius: 100px;
    background-color: $palette-grey-0;
    color: $palette-grey-500;
    padding: 1.5px 4px;
}

.my-profile-drawer {
    [class^="MuiBackdrop-root"],
    [class^="MuiPaper-root"] {
        position: absolute;
        height: 100%;
    }
}
.tooltip.clay-tooltip-top.show {
    z-index: 1500;
}
