.esp-theme-table {
    &.esp-empty-table {
        height: 100%;
    }

    .table-responsive {
        height: 100%;
        .esp-table {
            thead {
                tr {
                    .table-list-title {
                        border: none !important;
                        font-size: $text-md !important;
                        font-weight: bold !important;

                        .esp-table-header {
                            word-break: break-all;
                            white-space: break-spaces;
                        }
                    }
                }
            }

            tbody {
                .esp-table-row {
                    .esp-table-cell {
                        word-break: break-all;
                        white-space: break-spaces;
                    }
                }
            }
        }
    }
}

.esp-table {
    thead {
        tr {
            .esp-table-header {
                white-space: break-spaces;
                font-family: $primary-header-font;
            }
        }
    }

    tbody {
        .esp-table-row {
            .esp-table-cell {
                white-space: break-spaces;
            }
        }
    }
}
