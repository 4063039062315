// Panel Header

$panel-header-border-bottom-width: 0px !default;
$panel-header-font-size: 0.875rem !default; // 14px
$panel-header-link-hover-text-decoration: none !default;

$panel-header-collapse-icon-font-size: 0.75rem !default; // 12px

$panel-header-link: () !default;
$panel-header-link: map-deep-merge(
	(
		transition: box-shadow 0.15s ease-in-out,
		focus: (
			box-shadow: $component-focus-box-shadow,
			outline: 0,
		),
	),
	$panel-header-link
);

$panel-header-collapsed-link: () !default;
$panel-header-collapsed-link: map-deep-merge(
	(
		border-color: null,
		transition: null,
	),
	$panel-header-collapsed-link
);

// Panel Footer

$panel-footer-border-top-width: 0px !default;

// Panel Title

$panel-title-font-size: 0.875rem !default; // 14px
$panel-title-text-transform: uppercase !default;

// Panel Title Small Element

$panel-title-small-font-size: 100% !default;
$panel-title-small-margin-left: 0.375rem !default; // 6px

// Panel Group Sm

$panel-group-sm: () !default;
$panel-group-sm: map-deep-merge(
	(
		panel-title: (
			font-size: 0.75rem,
		),
	),
	$panel-group-sm
);

// Panel Variants

$panel-secondary: () !default;
$panel-secondary: map-deep-merge(
	(
		border-color: $gray-300,
		color: $gray-600,
		panel-header: (
			background-color: null,
			border-color: $gray-300,
		),
		panel-footer: (
			background-color: null,
			border-color: $gray-300,
		),
	),
	$panel-secondary
);

// Panel Unstyled

$panel-unstyled-header-link: () !default;
$panel-unstyled-header-link: map-deep-merge(
	(
		border-radius: 1px,
		focus: (
			box-shadow: #{0 0 0 0.25rem $body-bg,
			0 0 0 0.375rem $primary-l1},
		),
	),
	$panel-unstyled-header-link
);
