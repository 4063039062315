// .badge

$badge-border-color: transparent !default;
$badge-border-style: solid !default;
$badge-border-width: 1px !default;

$badge-border-radius: $border-radius !default;

$badge-line-height: 1 !default;
$badge-font-size: 75% !default;
$badge-font-weight: $font-weight-bold !default;
$badge-padding-x: 0.4em !default;
$badge-padding-y: 0.25em !default;
$badge-transition: $component-transition !default;

$badge-spacer-x: 0.25rem !default; // 4px
$badge-spacer-y: 0.125rem !default; // 2px

$badge-focus-width: $input-btn-focus-width !default;

// .badge a

$badge-link-color: $white !default;
$badge-link-hover-color: null !default;
$badge-link-text-decoration: underline !default;
$badge-link-hover-text-decoration: none !default;

$badge: () !default;
$badge: map-deep-merge(
	(
		border-color: $badge-border-color,
		border-radius: clay-enable-rounded($badge-border-radius),
		border-style: $badge-border-style,
		border-width: $badge-border-width,
		display: inline-flex,
		font-size: $badge-font-size,
		font-weight: $badge-font-weight,
		line-height: $badge-line-height,
		margin-bottom: $badge-spacer-y,
		margin-right: $badge-spacer-x,
		margin-top: $badge-spacer-y,
		max-width: 100%,
		padding-bottom: $badge-padding-y,
		padding-left: $badge-padding-x,
		padding-right: $badge-padding-x,
		padding-top: $badge-padding-y,
		text-align: center,
		transition: clay-enable-transitions($badge-transition),
		white-space: inherit,
		word-wrap: break-word,
		empty: (
			display: none,
		),
		href: (
			hover: (
				text-decoration: none,
			),
			focus: (
				box-shadow: $component-focus-box-shadow,
				outline: 0,
			),
		),
		link: (
			color: $badge-link-color,
			text-decoration: $badge-link-text-decoration,
			hover: (
				color: $badge-link-hover-color,
				text-decoration: $badge-link-hover-text-decoration,
			),
			focus: (
				color: $badge-link-hover-color,
				text-decoration: $badge-link-hover-text-decoration,
			),
		),
		c-inner: (
			margin-bottom: math-sign($badge-padding-y),
			margin-left: math-sign($badge-padding-x),
			margin-right: math-sign($badge-padding-x),
			margin-top: math-sign($badge-padding-y),
			max-width: none,
		),
	),
	$badge
);

// .badge-item .lexicon-icon

$badge-lexicon-icon-height: 0.875em !default;
$badge-lexicon-icon-margin-top: 0 !default;
$badge-lexicon-icon-width: 0.875em !default;

// .badge-item-expand

$badge-item-expand-min-width: 0.375rem !default;

// .badge-item-before, .badge-item-after

$badge-item-spacer-x: 0.5em !default;

// .badge-close

$badge-close: () !default;
$badge-close: map-deep-merge(
	(
		border-radius: 1px,
		color: inherit,
		display: inline-flex,
		font-size: inherit,
		height: auto,
		opacity: 1,
		width: auto,
		hover: (
			color: inherit,
			opacity: 1,
		),
		focus: (
			opacity: 1,
		),
	),
	$badge-close
);

// .badge-pill

$badge-pill-border-radius: 10rem !default;
$badge-pill-padding-x: 0.6em !default;

$badge-pill: () !default;
$badge-pill: map-deep-merge(
	(
		border-radius: clay-enable-rounded($badge-pill-border-radius),
		padding-left: $badge-pill-padding-x,
		padding-right: $badge-pill-padding-x,
	),
	$badge-pill
);

// .badge-primary

$badge-primary-bg: $primary !default;
$badge-primary-hover-bg: clay-darken($primary, 10%) !default;
$badge-primary-border-color: null !default;
$badge-primary-hover-border-color: null !default;
$badge-primary-color: color-yiq($primary) !default;
$badge-primary-hover-color: $badge-primary-color !default;
$badge-primary-link-color: null !default;
$badge-primary-link-hover-color: null !default;

$badge-primary: () !default;
$badge-primary: map-deep-merge(
	(
		background-color: $badge-primary-bg,
		border-color: $badge-primary-border-color,
		color: $badge-primary-color,
		href: (
			hover: (
				background-color: $badge-primary-hover-bg,
				border-color: $badge-primary-hover-border-color,
				color: $badge-primary-hover-color,
			),
			focus: (
				background-color: $badge-primary-hover-bg,
				border-color: $badge-primary-hover-border-color,
				box-shadow: 0 0 0 $badge-focus-width
					rgba($badge-primary-bg, 0.5),
				color: $badge-primary-hover-color,
			),
		),
		link: (
			color: $badge-primary-link-color,
			hover: (
				color: $badge-primary-link-hover-color,
			),
			focus: (
				color: $badge-primary-link-hover-color,
			),
		),
	),
	$badge-primary
);

// .badge-secondary

$badge-secondary-bg: $secondary !default;
$badge-secondary-hover-bg: clay-darken($secondary, 10%) !default;
$badge-secondary-border-color: null !default;
$badge-secondary-hover-border-color: null !default;
$badge-secondary-color: color-yiq($secondary) !default;
$badge-secondary-hover-color: $badge-secondary-color !default;
$badge-secondary-link-color: null !default;
$badge-secondary-link-hover-color: null !default;

$badge-secondary: () !default;
$badge-secondary: map-deep-merge(
	(
		background-color: $badge-secondary-bg,
		border-color: $badge-secondary-border-color,
		color: $badge-secondary-color,
		href: (
			hover: (
				background-color: $badge-secondary-hover-bg,
				border-color: $badge-secondary-hover-border-color,
				color: $badge-secondary-hover-color,
			),
			focus: (
				background-color: $badge-secondary-hover-bg,
				border-color: $badge-secondary-hover-border-color,
				color: $badge-secondary-hover-color,
				box-shadow: 0 0 0 $badge-focus-width
					rgba($badge-secondary-bg, 0.5),
			),
		),
		link: (
			color: $badge-secondary-link-color,
			hover: (
				color: $badge-secondary-link-hover-color,
			),
			focus: (
				color: $badge-secondary-link-hover-color,
			),
		),
	),
	$badge-secondary
);

// .badge-success

$badge-success-bg: $success !default;
$badge-success-hover-bg: clay-darken($success, 10%) !default;
$badge-success-border-color: null !default;
$badge-success-hover-border-color: null !default;
$badge-success-color: color-yiq($success) !default;
$badge-success-hover-color: $badge-success-color !default;
$badge-success-link-color: null !default;
$badge-success-link-hover-color: null !default;

$badge-success: () !default;
$badge-success: map-deep-merge(
	(
		background-color: $badge-success-bg,
		border-color: $badge-success-border-color,
		color: $badge-success-color,
		href: (
			hover: (
				background-color: $badge-success-hover-bg,
				border-color: $badge-success-hover-border-color,
				color: $badge-success-hover-color,
			),
			focus: (
				background-color: $badge-success-hover-bg,
				border-color: $badge-success-hover-border-color,
				color: $badge-success-hover-color,
				box-shadow: 0 0 0 $badge-focus-width
					rgba($badge-success-bg, 0.5),
			),
		),
		link: (
			color: $badge-success-link-color,
			hover: (
				color: $badge-success-link-hover-color,
			),
			focus: (
				color: $badge-success-link-hover-color,
			),
		),
	),
	$badge-success
);

// .badge-info

$badge-info-bg: $info !default;
$badge-info-hover-bg: clay-darken($info, 10%) !default;
$badge-info-border-color: null !default;
$badge-info-hover-border-color: null !default;
$badge-info-color: color-yiq($info) !default;
$badge-info-hover-color: $badge-info-color !default;
$badge-info-link-color: null !default;
$badge-info-link-hover-color: null !default;

$badge-info: () !default;
$badge-info: map-deep-merge(
	(
		background-color: $badge-info-bg,
		border-color: $badge-info-border-color,
		color: $badge-info-color,
		href: (
			hover: (
				background-color: $badge-info-hover-bg,
				border-color: $badge-info-hover-border-color,
				color: $badge-info-hover-color,
			),
			focus: (
				background-color: $badge-info-hover-bg,
				border-color: $badge-info-hover-border-color,
				color: $badge-info-hover-color,
				box-shadow: 0 0 0 $badge-focus-width rgba($badge-info-bg, 0.5),
			),
		),
		link: (
			color: $badge-info-link-color,
			hover: (
				color: $badge-info-link-hover-color,
			),
			focus: (
				color: $badge-info-link-hover-color,
			),
		),
	),
	$badge-info
);

// .badge-warning

$badge-warning-bg: $warning !default;
$badge-warning-hover-bg: clay-darken($warning, 10%) !default;
$badge-warning-border-color: null !default;
$badge-warning-hover-border-color: null !default;
$badge-warning-color: color-yiq($warning) !default;
$badge-warning-hover-color: $badge-warning-color !default;
$badge-warning-link-color: null !default;
$badge-warning-link-hover-color: null !default;

$badge-warning: () !default;
$badge-warning: map-deep-merge(
	(
		background-color: $badge-warning-bg,
		border-color: $badge-warning-border-color,
		color: $badge-warning-color,
		href: (
			hover: (
				background-color: $badge-warning-hover-bg,
				border-color: $badge-warning-hover-border-color,
				color: $badge-warning-hover-color,
			),
			focus: (
				background-color: $badge-warning-hover-bg,
				border-color: $badge-warning-hover-border-color,
				color: $badge-warning-hover-color,
				box-shadow: 0 0 0 $badge-focus-width
					rgba($badge-warning-bg, 0.5),
			),
		),
		link: (
			color: $badge-warning-link-color,
			hover: (
				color: $badge-warning-link-hover-color,
			),
			focus: (
				color: $badge-warning-link-hover-color,
			),
		),
	),
	$badge-warning
);

// .badge-danger

$badge-danger-bg: $danger !default;
$badge-danger-hover-bg: clay-darken($danger, 10%) !default;
$badge-danger-border-color: null !default;
$badge-danger-hover-border-color: null !default;
$badge-danger-color: color-yiq($danger) !default;
$badge-danger-hover-color: $badge-danger-color !default;
$badge-danger-link-color: null !default;
$badge-danger-link-hover-color: null !default;

$badge-danger: () !default;
$badge-danger: map-deep-merge(
	(
		background-color: $badge-danger-bg,
		border-color: $badge-danger-border-color,
		color: $badge-danger-color,
		href: (
			hover: (
				background-color: $badge-danger-hover-bg,
				border-color: $badge-danger-hover-border-color,
				color: $badge-danger-hover-color,
			),
			focus: (
				background-color: $badge-danger-hover-bg,
				border-color: $badge-danger-hover-border-color,
				color: $badge-danger-hover-color,
				box-shadow: 0 0 0 $badge-focus-width rgba($badge-danger-bg, 0.5),
			),
		),
		link: (
			color: $badge-danger-link-color,
			hover: (
				color: $badge-danger-link-hover-color,
			),
			focus: (
				color: $badge-danger-link-hover-color,
			),
		),
	),
	$badge-danger
);

// .badge-light

$badge-light-bg: $light !default;
$badge-light-hover-bg: clay-darken($light, 10%) !default;
$badge-light-border-color: null !default;
$badge-light-hover-border-color: null !default;
$badge-light-color: color-yiq($light) !default;
$badge-light-hover-color: $badge-light-color !default;
$badge-light-link-color: null !default;
$badge-light-link-hover-color: null !default;

$badge-light: () !default;
$badge-light: map-deep-merge(
	(
		background-color: $badge-light-bg,
		border-color: $badge-light-border-color,
		color: $badge-light-color,
		href: (
			hover: (
				background-color: $badge-light-hover-bg,
				border-color: $badge-light-hover-border-color,
				color: $badge-light-hover-color,
			),
			focus: (
				background-color: $badge-light-hover-bg,
				border-color: $badge-light-hover-border-color,
				color: $badge-light-hover-color,
				box-shadow: 0 0 0 $badge-focus-width rgba($badge-light-bg, 0.5),
			),
		),
		link: (
			color: $badge-light-link-color,
			hover: (
				color: $badge-light-link-hover-color,
			),
			focus: (
				color: $badge-light-link-hover-color,
			),
		),
	),
	$badge-light
);

// .badge-dark

$badge-dark-bg: $dark !default;
$badge-dark-hover-bg: clay-darken($dark, 10%) !default;
$badge-dark-border-color: null !default;
$badge-dark-hover-border-color: null !default;
$badge-dark-color: color-yiq($dark) !default;
$badge-dark-hover-color: $badge-dark-color !default;
$badge-dark-link-color: null !default;
$badge-dark-link-hover-color: null !default;

$badge-dark: () !default;
$badge-dark: map-deep-merge(
	(
		background-color: $badge-dark-bg,
		border-color: $badge-dark-border-color,
		color: $badge-dark-color,
		href: (
			hover: (
				background-color: $badge-dark-hover-bg,
				border-color: $badge-dark-hover-border-color,
				color: $badge-dark-hover-color,
			),
			focus: (
				background-color: $badge-dark-hover-bg,
				border-color: $badge-dark-hover-border-color,
				color: $badge-dark-hover-color,
				box-shadow: 0 0 0 $badge-focus-width rgba($badge-dark-bg, 0.5),
			),
		),
		link: (
			color: $badge-dark-link-color,
			hover: (
				color: $badge-dark-link-hover-color,
			),
			focus: (
				color: $badge-dark-link-hover-color,
			),
		),
	),
	$badge-dark
);

$badge-palette: () !default;
$badge-palette: map-deep-merge(
	(
		primary: $badge-primary,
		secondary: $badge-secondary,
		success: $badge-success,
		info: $badge-info,
		warning: $badge-warning,
		danger: $badge-danger,
		light: $badge-light,
		dark: $badge-dark,
	),
	$badge-palette
);
