.menubar {
	position: relative;
}

.menubar-toggler {
	@extend %btn-unstyled !optional;

	display: none;
}

// Menubar Vertical MD

.menubar-vertical-expand-md {
	@include clay-menubar-vertical-expand($menubar-vertical-expand-md);

	&.menubar-transparent {
		@include clay-menubar-vertical-variant(
			$menubar-vertical-transparent-md
		);
	}

	&.menubar-decorated {
		@include media-breakpoint-up(md) {
			.nav {
				@include clay-css($menubar-vertical-decorated-md-nav);

				> .nav-item .nav {
					@include clay-css(
						$menubar-vertical-decorated-md-nav-item-nav
					);
				}
			}

			.nav-link {
				@include clay-css($menubar-vertical-decorated-md-nav-link);

				&.active::after {
					@include clay-css(
						$menubar-vertical-decorated-md-nav-link-after-active
					);
				}
			}
		}
	}
}

// Menubar Vertical LG

.menubar-vertical-expand-lg {
	@include clay-menubar-vertical-expand($menubar-vertical-expand-lg);

	&.menubar-transparent {
		@include clay-menubar-vertical-variant(
			$menubar-vertical-transparent-lg
		);
	}

	&.menubar-decorated {
		@include media-breakpoint-up(lg) {
			.nav {
				@include clay-css($menubar-vertical-decorated-lg-nav);

				> .nav-item .nav {
					@include clay-css(
						$menubar-vertical-decorated-lg-nav-item-nav
					);
				}
			}

			.nav-link {
				@include clay-css($menubar-vertical-decorated-lg-nav-link);

				&.active::after {
					@include clay-css(
						$menubar-vertical-decorated-lg-nav-link-after-active
					);
				}
			}
		}
	}
}
