%aspect-ratio {
	display: block;
	overflow: hidden;
	position: relative;

	@include clay-aspect-ratio;
}

.aspect-ratio {
	@extend %aspect-ratio;
}

// Aspect Ratio Item

%aspect-ratio-item {
	left: 0;
	position: absolute;
	word-wrap: break-word;
}

.aspect-ratio-item {
	@extend %aspect-ratio-item;
}

// Fluid

%aspect-ratio-item-fluid {
	max-width: 100%;
	position: absolute;
	word-wrap: break-word;
}

.aspect-ratio-item-fluid {
	@extend %aspect-ratio-item-fluid;
}

// Vertical Fluid

%aspect-ratio-item-vertical-fluid {
	max-height: 100%;
	position: absolute;
	word-wrap: break-word;
}

.aspect-ratio-item-vertical-fluid {
	@extend %aspect-ratio-item-vertical-fluid;
}

// Flush

%aspect-ratio-item-flush {
	max-width: none;
	position: absolute;
	width: 100.6%; // Fixes subpixel rendering issues
}

.aspect-ratio-item-flush {
	@extend %aspect-ratio-item-flush;
}

%aspect-ratio-item-vertical-flush {
	height: 100.6%; // Fixes subpixel rendering issues
	max-height: none;
	position: absolute;
}

.aspect-ratio-item-vertical-flush {
	@extend %aspect-ratio-item-vertical-flush;
}

// Top Left

%aspect-ratio-item-top-left {
	@include clay-position(top-left);
}

.aspect-ratio-item-top-left {
	@extend %aspect-ratio-item-top-left;
}

// Top Center

%aspect-ratio-item-top-center {
	@include clay-position(top-center);
}

.aspect-ratio-item-top-center {
	@extend %aspect-ratio-item-top-center;
}

// Top Right

%aspect-ratio-item-top-right {
	@include clay-position(top-right);
}

.aspect-ratio-item-top-right {
	@extend %aspect-ratio-item-top-right;
}

// Right Middle

%aspect-ratio-item-right-middle {
	@include clay-position(right-middle);
}

.aspect-ratio-item-right-middle {
	@extend %aspect-ratio-item-right-middle;
}

// Bottom Right

%aspect-ratio-item-bottom-right {
	@include clay-position(bottom-right);
}

.aspect-ratio-item-bottom-right {
	@extend %aspect-ratio-item-bottom-right;
}

// Bottom Center

%aspect-ratio-item-bottom-center {
	@include clay-position(bottom-center);
}

.aspect-ratio-item-bottom-center {
	@extend %aspect-ratio-item-bottom-center;
}

// Bottom Left

%aspect-ratio-item-bottom-left {
	@include clay-position(bottom-left);
}

.aspect-ratio-item-bottom-left {
	@extend %aspect-ratio-item-bottom-left;
}

// Left Middle

%aspect-ratio-item-left-middle {
	@include clay-position(left-middle);
}

.aspect-ratio-item-left-middle {
	@extend %aspect-ratio-item-left-middle;
}

// Center Middle

%aspect-ratio-item-center-middle {
	@include clay-position(center-middle);
}

.aspect-ratio-item-center-middle {
	@extend %aspect-ratio-item-center-middle;
}

// aspect-ratio-#-to-#

@each $selector, $value in $aspect-ratio-sizes {
	$selector: if(
		starts-with($selector, '.') or
			starts-with($selector, '#') or
			starts-with($selector, '%'),
		$selector,
		str-insert($selector, '.', 1)
	);

	@if (starts-with($selector, '%')) {
		#{$selector} {
			@include clay-aspect-ratio(
				map-get($value, width),
				map-get($value, height)
			);
		}
	} @else if (map-get($value, extend)) {
		#{$selector} {
			@include clay-css($value);
		}
	} @else {
		$placeholder: str-insert(
			str-slice($selector, 2, str-length($selector)),
			'%',
			1
		);

		#{$placeholder} {
			@include clay-aspect-ratio(
				map-get($value, width),
				map-get($value, height)
			);
		}

		#{$selector} {
			@extend #{$placeholder} !optional;
		}
	}
}

// Bg Contain

%aspect-ratio-bg-contain {
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.aspect-ratio-bg-contain {
	@extend %aspect-ratio-bg-contain;
}

// Bg Cover

%aspect-ratio-bg-cover {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.aspect-ratio-bg-cover {
	@extend %aspect-ratio-bg-cover;
}

// Bg Center

%aspect-ratio-bg-center {
	background-position: center;
	background-repeat: no-repeat;
}

.aspect-ratio-bg-center {
	@extend %aspect-ratio-bg-center;
}
