//shades of red
//$palette-red-0:     rgba(252, 234, 234, 1);
$palette-red-0: rgba(206, 9, 48, 1);
$palette-red-100: rgba(220, 32, 32, 1);
$palette-red-200: rgba(204, 9, 47, 1);
$palette-red-300: rgba(255, 0, 40, 0.1);

//shades of grey
$palette-grey: rgb(233, 236, 239, 1);
$palette-grey-0: rgba(248, 249, 250, 1);
$palette-grey-1: rgba(248, 249, 250, 1);
$palette-grey-100: rgba(233, 236, 239, 1);
$palette-grey-200: rgba(222, 226, 230, 1);
$palette-grey-300: rgba(206, 212, 218, 1);
$palette-grey-400: rgba(173, 181, 189, 1);
$palette-grey-450: rgba(167, 168, 170, 1);
$palette-grey-475: rgba(135, 135, 135, 1);
$palette-grey-480: rgba(128, 128, 128, 1);
$palette-grey-500: rgba(106, 113, 120, 1);
$palette-grey-550: rgba(83, 86, 90, 1);
$palette-grey-600: rgba(79, 87, 94, 1);
$palette-grey-700: rgba(39, 43, 48, 1);
$palette-grey-800: rgba(16, 18, 19, 1);

//shades of black
$palette-black-0: rgba(0, 0, 0, 1);
$palette-black-1: rgba(51, 56, 64, 0.08);

//shades of white
$palette-white-0: rgba(255, 255, 255, 1);
$palette-white-1: rgba(247, 247, 247, 1);
$palette-white-100: rgba(240, 245, 255, 1);
$palette-white-200: rgba(240, 243, 246, 1);
$palette-white-400: rgba(238, 238, 238, 1);
$palette-white-500: rgba(234, 235, 235, 1);
$palette-white-600: rgba(204, 204, 204, 1);

//shades of blue
$palette-blue-0: rgba(0, 92, 138, 1);
$palette-blue-100: rgba(229, 245, 252, 1);
$palette-blue-200: rgba(229, 240, 254, 1);
$palette-teal-0: rgba(0, 131, 160, 1);

//shades of green
$palette-green-0: rgba(241, 248, 232, 1);
$palette-green-1: rgba(0, 34, 0, 1);
$palette-green-100: rgba(116, 184, 22, 1);
$palette-green-200: rgba(41, 130, 59, 1);
$palette-green-300: rgba(47, 151, 65, 1);

//shades of orange
$palette-orange-0: rgba(253, 246, 229, 1);
$palette-orange-100: rgba(235, 160, 0, 1);

//Opacities of Black
$black-opt-6: rgba(0, 0, 0, 0.6);
$black-opt-8: rgba(0, 0, 0, 0.8);

//opacities of white
$white-opt-4: rgba(255, 255, 255, 0.4);
$white-opt-7: rgba(255, 255, 255, 0.7);
$white-opt-8: rgba(255, 255, 255, 0.8);

//social color
$palette-fb: rgba(59, 89, 152, 1);
$palette-twitter: rgb(29, 161, 242, 1);
$palette-linkedin: rgb(0, 119, 181, 1);
$palette-youtube: rgb(205, 32, 31, 1);
