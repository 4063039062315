.error-banner {
    padding-left: 3%;
    padding-right: 4%;
    padding-top: 1%;
    padding-bottom: 0%;
}

.error-title {
    font-size: 2rem;
    color: rgb(204, 9, 47);
}

.error-text {
    font-size: 1rem;
}
