.confirmation-modal .modal-dialog {
    height: 100%;
    margin: 0;
    max-width: 100%;

    .modal-content {
        margin: 0 auto;
        width: 25%;

        padding: 1rem 1rem 0.5rem;

        .modal-header {
            padding: 1rem;
        }

        .modalHeader {
            border: none;
            height: 3rem;
        }

        .modalBody {
            border: none;
            padding: 1rem 1rem 1rem;

            p {
                margin: 0;
            }
        }

        .modalFooter {
            border: none !important;

            .modal-item {
                align-items: center;

                .modalButton {
                    padding: 0.5rem 1.3rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 425px) {
    .modal-dialog {
        .modal-content {
            width: 80%;
        }
    }
}
