.skeleton-shape-animate {
    background-size: 600px;
    background-color: $palette-white-200;
    background-image: linear-gradient(90deg, $white-opt-4 0px, 50px, $palette-white-0 100px);
    animation: shine-lines 4s infinite ease-out;
}

.skeleton-wrapper {
    padding: 2rem;
}

.skeleton-title {
    width: 300px;
    height: 25px;
    border-radius: 0.25rem;
    background-size: 600px;
}

.skeleton-button {
    width: 180px;
    height: 40px;
    border-radius: 5px;
}

.skeleton-tab-title {
    width: 60px;
    height: 20px;
    margin-right: 0.5rem;
}

.skeleton-card {
    height: 250px;
}
.skeleton-control {
    width: 100px;
    height: 20px;
    margin-right: 0.5rem;
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }
    40%,
    100% {
        background-position: 340px;
    }
}
